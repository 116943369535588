import h from 'hyperscript';

import { Modal } from 'ui/dialog/modal/modal';

import './offline-modal.css';
import i18n from './offline-modal.properties';

export const COMPONENT_NAME = 'offline-modal';

export class OfflineModal extends Modal {

  constructor () {
    super({
      title: i18n('heading'),
      content: () => Promise.resolve(h('p', i18n('msg'))),
      submitButton: i18n('primaryBtn')
    });
    this.header.prepend(h('d-progress-spinner.d-progress-spinner-xs'));
    this.dialog.classList.add(COMPONENT_NAME);
  }

}

customElements.define(COMPONENT_NAME, OfflineModal);
